import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import BrowseBox from '../components/BrowseBox'
import ImageHeader from '../components/ImageHeader'
import AdComponent from "../components/AdComponent"
import Layout from '../components/Layout'
import PerspectivesBox from '../components/PerspectivesBox'
import {useStaticQuery, graphql} from 'gatsby'
import '../styles/resources-page.scss'

const ResourcesPage = () => {
    const data = useStaticQuery(graphql`
        query MyQuery {
            wp {
                resourcesGeneralSettings {
                    resourceLibraryHeader {
                        headerImage {
                            sourceUrl
                        }
                    }
                }
            }
            allWpMereResource(sort: {fields: date, order: DESC}, limit: 300, filter: {status: {eq: "publish"}}) {
                edges {
                    node {
                        title
                        featuredImage {
                            node {
                                mediaDetails {
                                    sizes {
                                        sourceUrl
                                        name
                                    }
                                }
                            }
                        }
                        bookFields {
                            bookCoverImage {
                                mediaDetails {
                                    sizes {
                                        name
                                        sourceUrl
                                    }
                                }
                            }
                            bookAuthor
                        }
                        excerpt
                        uri
                        date
                        multiauthor {
                            nodes {
                                name
                                slug
                            }
                        }
                        resourceTypes {
                            nodes {
                                name
                            }
                        }
                        status
                    }
                }
            }
            allWpAd(
                filter: {adPositions: {nodes: {elemMatch: {slug: {in: ["above-card-grid"]}}}}}
            ) {
                nodes {
                    id
                    title
                    adPositions {
                        nodes {
                            slug
                        }
                    }
                    adFields {
                        altText
                        endDate
                        externalLink
                        startDate
                    }
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    `)

    const truncate = (str, max, suffix) =>
        str.length < max
            ? str
            : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`

    return (
        <Layout>
            <ImageHeader title="Explore the Library"
                         image={data.wp.resourcesGeneralSettings.resourceLibraryHeader.headerImage.sourceUrl}/>
            <Breadcrumbs title="Resources"/>
            <div className="container">
                <section className="resources-grid">
                    <div className="resources-grid__subgrid">
                        <div className="resources-grid__subgrid__title">
                            <h3>Articles</h3>
                            <a href="/resources/articles" className="btn resources-grid__subgrid__title__button">See
                                All Articles</a>
                        </div>
                        <div className="resources-grid__subgrid__inner">
                            {data.allWpMereResource.edges.filter((item) => item.node.resourceTypes.nodes[0].name === 'Articles').slice(0, 3).map((post, index) =>
                                <div className="resources-grid__subgrid__inner__card"
                                     style={{backgroundImage: `url("${post.node.featuredImage.node.mediaDetails.sizes.find(size => size.name === 'large')?.sourceUrl}")`}}>
                                    <h4>{post.node.title} </h4>
                                    {post.node.multiauthor.nodes[0] &&
                                    <div className="resources-grid__subgrid__inner__card__author">
                                        By {post.node.multiauthor.nodes.map((term, index) =>
                                        <span>
                                            {post.node.multiauthor.nodes.length - 1 === index && index !== 0 && " and "}
                                            {post.node.multiauthor.nodes.length - 1 !== index && index !== 0 && ", "}
                                            {term.name}
                                            {post.node.multiauthor.nodes.length - 1 !== index && "\u00a0"}
                                            </span>
                                    )}
                                    </div>
                                    }
                                    <div className="resources-grid__subgrid__inner__card__excerpt"
                                         dangerouslySetInnerHTML={{__html: truncate(post.node.excerpt, 100, '...') }}/>
                                    <a className="resources-grid__subgrid__inner__card__link-overlay"
                                       href={post.node.uri}/>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="resources-grid__subgrid">
                        <div className="resources-grid__subgrid__title">
                            <h3>Questions</h3>
                            <a href="/resources/questions"
                               className="btn resources-grid__subgrid__title__button">See All Questions</a>
                        </div>
                        <div className="resources-grid__subgrid__inner">
                            {data.allWpMereResource.edges.filter((item) => item.node.resourceTypes.nodes[0].name === 'Questions').slice(0, 3).map((post, index) =>
                                <div className="resources-grid__subgrid__inner__card"
                                     style={{backgroundImage: `url("${post.node.featuredImage?.node.mediaDetails.sizes.find(size => size.name === 'large')?.sourceUrl}")`}}>
                                    <h4>{post.node.title}</h4>
                                    {post.node.multiauthor.nodes[0] &&
                                    <div className="resources-grid__subgrid__inner__card__author">
                                        By {post.node.multiauthor.nodes.map((term, index) =>
                                        <span>
                                            {post.node.multiauthor.nodes.length - 1 === index && index !== 0 && " and "}
                                            {post.node.multiauthor.nodes.length - 1 !== index && index !== 0 && ", "}
                                            {term.name}
                                            {post.node.multiauthor.nodes.length - 1 !== index && "\u00a0"}
                                            </span>
                                    )}
                                    </div>
                                    }
                                    <div className="resources-grid__subgrid__inner__card__excerpt"
                                         dangerouslySetInnerHTML={{__html: truncate(post.node.excerpt, 100, '...') }}/>
                                    <a className="resources-grid__subgrid__inner__card__link-overlay"
                                       href={post.node.uri}/>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </div>
            <BrowseBox/>
            <AdComponent ads={data.allWpAd.nodes} position="above-card-grid" />
            <PerspectivesBox />
            <div className="container">
                <section className="resources-grid">
                    <div className="resources-grid__subgrid">
                        <div className="resources-grid__subgrid__title">
                            <h3>Devotionals</h3>
                            <a href="/resources/devotionals"
                               className="btn resources-grid__subgrid__title__button">See All</a>
                        </div>
                        <div className="resources-grid__subgrid__inner resources-grid__subgrid__inner--smaller">
                            {data.allWpMereResource.edges.filter((item) => item.node.resourceTypes.nodes[0].name === 'Devotionals').slice(0, 4).map((post, index) =>
                                <div
                                    className="resources-grid__subgrid__inner__card resources-grid__subgrid__inner__card--devotional"
                                    style={{backgroundImage: `url("${post.node.featuredImage.node.mediaDetails.sizes.find(size => size.name === 'large')?.sourceUrl}")`}}>
                                    <h4>{post.node.title}</h4>
                                    {post.node.multiauthor.nodes[0] &&
                                    <div className="resources-grid__subgrid__inner__card__author">
                                        By {post.node.multiauthor.nodes.map((term, index) =>
                                            <span>
                                            {post.node.multiauthor.nodes.length - 1 === index && index !== 0 && " and "}
                                                {post.node.multiauthor.nodes.length - 1 !== index && index !== 0 && ", "}
                                                {term.name}
                                                {post.node.multiauthor.nodes.length - 1 !== index && "\u00a0"}
                                            </span>
                                    )}
                                    </div>
                                    }
                                    <div className="resources-grid__subgrid__inner__card__excerpt"
                                         dangerouslySetInnerHTML={{__html: truncate(post.node.excerpt, 100, '...') }}/>
                                    <a className="resources-grid__subgrid__inner__card__link-overlay"
                                       href={post.node.uri}/>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="resources-grid__subgrid">
                        <div className="resources-grid__subgrid__title">
                            <h3>Verse Collections</h3>
                            <a href="/resources/verse-collections"
                               className="btn resources-grid__subgrid__title__button">See All</a>
                        </div>
                        <div className="resources-grid__subgrid__inner resources-grid__subgrid__inner--smaller">
                            {data.allWpMereResource.edges.filter((item) => item.node.resourceTypes.nodes[0].name === 'Verse Collections').slice(0, 4).map((post, index) =>
                                <div
                                    className="resources-grid__subgrid__inner__card resources-grid__subgrid__inner__card--devotional"
                                    style={{backgroundImage: `url("${post.node.featuredImage.node.mediaDetails.sizes.find(size => size.name === 'large')?.sourceUrl}")`}}>
                                    <h4>{post.node.title}</h4>
                                    <div className="resources-grid__subgrid__inner__card__excerpt"
                                         dangerouslySetInnerHTML={{__html: truncate(post.node.excerpt, 100, '...') }}/>
                                    <a className="resources-grid__subgrid__inner__card__link-overlay"
                                       href={post.node.uri}/>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                <section className="resources-grid resources-grid--books">
                    <div className="resources-grid__subgrid resources-grid__subgrid--books">
                        <div className="resources-grid__subgrid resources-grid__subgrid--books">
                            <div className="resources-grid__subgrid__title">
                                <h3>Books</h3>
                                <a href="/resources/books" className="btn resources-grid__subgrid__title__button">See
                                    All Books</a>
                            </div>
                            <div className="resources-grid__subgrid__inner--books">
                                {data.allWpMereResource.edges.filter((item) => item.node.resourceTypes.nodes[0].name === 'Books').slice(0, 6).map((post, index) =>
                                    <div className="resources-grid__subgrid__inner--books__card">
                                        <img src={post.node.bookFields.bookCoverImage.mediaDetails.sizes.find(size => size.name === 'large')?.sourceUrl} alt={`Book cover for ${post.node.title}`}/>
                                        <h4>{post.node.title}</h4>
                                        <span className="resources-grid__subgrid__inner__card__author">
                                        {post.node.bookFields.bookAuthor}
                                    </span>
                                        <a className="resources-grid__subgrid__inner__card__link-overlay"
                                           href={post.node.uri}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Breadcrumbs title="Resources"/>
        </Layout>
    )
}

export default ResourcesPage
